<template>
  <div class="won-list-item">
    <div
      class="extension-won-cmn__row"
      :class="{
        'extension-won-cmn__row_won': isWonTemplate,
        'extension-won-cmn__row_history': isHistoryTemplate
      }"
    >
      <won-car-cell :item="item" :template="template" />

      <won-user-cell :item="item" :template="template" />

      <div class="cells-cmn__cell">
        <span class="cells-cmn__str cells-cmn__str_sec">
          <span>
            {{ item.vehicle.docType || $t('COMMON.SYMBOLS.MDASH') }}
          </span>
        </span>
      </div>

      <div class="cells-cmn__cell">
        <span class="cells-cmn__str cells-cmn__str_sec">
          {{ item.vehicle.location }}
        </span>
      </div>

      <template v-if="isWonTemplate">
        <div class="cells-cmn__cell">
          <span class="cells-cmn__str cells-cmn__str_sec">
            {{ $fc(item.bidAmount, item.currency) }}
          </span>
        </div>

        <div class="cells-cmn__cell">
          <span class="cells-cmn__str cells-cmn__str_sec">
            {{ $fc(item.feeAmount, item.currency) }}
          </span>
        </div>

        <won-invoice-cell :item="item" :template="template" />

        <div class="cells-cmn__cell">
          <span class="cells-cmn__str cells-cmn__str_sec">
            {{ $fc(item.amountDue, item.currency) }}
          </span>
        </div>
      </template>

      <template v-else-if="template === EXTENSION_WON_LIST_TEMPLATES.openItems">
        <won-status-cell :item="item" />

        <div class="cells-cmn__cell">
          <span class="cells-cmn__str cells-cmn__str_sec">
            {{ $fc(item.currentBid, item.currency) }}
          </span>
        </div>

        <div class="cells-cmn__cell">
          <span class="cells-cmn__str cells-cmn__str_sec">
            {{ $fc(item.myBid, item.currency) }}
          </span>
        </div>
      </template>

      <template v-else-if="template === EXTENSION_WON_LIST_TEMPLATES.history">
        <won-date-cell :date="item.leftLocationDate" />
        <won-invoice-cell :item="item" :template="template" />
      </template>
    </div>
  </div>
</template>

<script>
import WonCarCell from './WonCarCell'
import WonUserCell from './WonUserCell'
import WonStatusCell from './WonStatusCell'
import WonDateCell from './WonDateCell'
import WonInvoiceCell from './WonInvoiceCell'

import { EXTENSION_WON_LIST_TEMPLATES } from '../constants'
import { CopartWinner } from 'Models/CopartWinner'

export default {
  name: 'won-list-item',
  components: {
    WonCarCell,
    WonUserCell,
    WonStatusCell,
    WonDateCell,
    WonInvoiceCell
  },

  props: {
    template: {
      type: String,
      default: EXTENSION_WON_LIST_TEMPLATES.won,
      validator(value) {
        return Object.values(EXTENSION_WON_LIST_TEMPLATES).includes(value)
      }
    },

    item: {
      type: CopartWinner,
      required: true
    }
  },

  data() {
    return {
      EXTENSION_WON_LIST_TEMPLATES
    }
  },

  computed: {
    isWonTemplate() {
      return (
        this.template === EXTENSION_WON_LIST_TEMPLATES.won ||
        this.template === EXTENSION_WON_LIST_TEMPLATES.notPaid
      )
    },

    isHistoryTemplate() {
      return this.template === EXTENSION_WON_LIST_TEMPLATES.history
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/common/cells/cells.scss';
@import '../styles/extension-won.scss';

.won-list-item {
  padding: 2.1em 0;
  border-bottom: 1px solid rgba($color-grey, 0.5);
  position: relative;
  &__invoice {
    color: $color-sys-info;
  }
}
</style>
